
import { defineAsyncComponent } from "vue";
import { Vue, Options } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import debounce from "lodash/debounce";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { AccountController } from "@/app/ui/controllers/AccountController";
import router from "@/app/ui/router";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { paginationStyle } from "../booking-utils";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import ModalInstantBoookingConfirmation from "../component/modal-instant-booking-confirmation.vue";
import { InstantBookingController } from "@/app/ui/controllers/InstantBookingController";
import { ShipmentBooking } from "@/domain/entities/ShipmentBooking";
import { STIController } from "@/app/ui/controllers/STIController";
import { STISTTDueSummaryListRequest } from "@/data/payload/api/StiApiRequest";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";

const ModalErrorBalance = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/booking/component/modal-error-balance.vue")
});
const DetailRejectPopup = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/shipment/booking/detail-booking/detail-reject-popup.vue"
    )
});

@Options({
  components: {
    DetailRejectPopup,
    OverlayPanel,
    ModalErrorBalance,
    ModalInstantBoookingConfirmation,
    Skeleton,
    CustomDropdown
  },
  computed: {
    isDetailAble() {
      return FlagsPermissionBooking.permission_booking_view_detail.isEnabled();
    },
    isAbleToCreateShipmentId() {
      return FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled();
    },
    isCreateManualAble() {
      return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
    },
    isCreateForCorporateAble() {
      return (
        FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled() &&
        !this.dataProfile.isPosChildAccount
      );
    },
    isAbleToCreateInstantBooking() {
      return FlagsPermissionBooking.permission_instant_booking_create.isEnabled();
    },
    isAbleToAdvanceFilter() {
      return FlagsPermissionBooking.permission_advance_filter_client_partner_click.isEnabled();
    }
  }
})
export default class TabListBooking extends Vue {
  tncModal = false;
  intervalId: any = "";
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }
  get isMigratingFromElexys() {
    return process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true";
  }

  get paginationStyle() {
    return paginationStyle;
  }

  phoneNumber = ""

  beforeMount() {
    this.fetchNeedStiSummary();
  }

  mounted() {
    this.phoneNumber = this.$route.query.phoneNumber?.toString() || ""
    this.onSetRefs();
    ShipmentBookingController.initShipmentBooking(this.phoneNumber);
    BalanceController.getBalance(true);
    this.intervalId = setInterval(() => {
      this.fetchNeedStiSummary();
    }, 600000); // 600000 millisecond = 10 minute
  }

  unmounted(): void {
    this.onResetAdvancedFilter();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  get getPhoneNumber() {
    return this.phoneNumber;
  }

  onSetRefs() {
    this.refs = this.$refs;
  }

  get validationAccount() {
    return AccountController.validationAccount;
  }

  onRequest() {
    ShipmentBookingController.fetchShipmentBooking();
  }

  get firstRequest() {
    return ShipmentBookingController.firstRequest;
  }

  get shipmentBooking() {
    return ShipmentBookingController.shipmentBookingData.data.map(
      (item: ShipmentBooking) => {
        return {
          ...item,
          customDeleteAction: this.isMigratingFromElexys
            ? false
            : this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
        };
      }
    );
  }
  
  get addSumFilter() {
    return ShipmentBookingController.advanceFilterData.nestedStatus.length
  }

  //Filter Search
  search = "";
  onSearch(value: string) {
    ShipmentBookingController.searchAction(value);
  }
  get searchValue() {
    return ShipmentBookingController.search;
  }
  clearSearch() {
    ShipmentBookingController.clear();
    this.search = "";
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter(event: any) {
    ShipmentBookingController.setAdvanceFilterData(event);
    this.pagination.page = 1;
    this.onRequest();
  }
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter() {
    ShipmentBookingController.setAdvanceFilterData({
      periodeStart: "",
      periodeEnd: "",
      originCode: "",
      destinationCode: "",
      clientPartnerId: "",
      status: "",
      product: [],
      COD: false,
      PAD: false,
      DFOD: false,
      asuransi: [],
      nestedStatus: []
    });
    this.pagination.page = 1;
    this.onRequest();
  }
  
  get advancedFilterData() {
    return ShipmentBookingController.advanceFilterData;
  }

  //Filter assesstment status
  sttAssessmentStatus: any = {};
  get sttAssessmentStatusData() {
    return [
      { name: "Semua", value: "" },
      { name: "Menunggu", value: "waiting" },
      { name: "Ditolak", value: "rejected" },
      { name: "Diterima", value: "approved" }
    ];
  };
  
  onSelectSttAssessmentStatus(item: any) {
    this.sttAssessmentStatus = item.value
    ShipmentBookingController.selectSttAssessmentStatusAction(item.value)
  };

  // Pagination Table
  get pagination() {
    return ShipmentBookingController.shipmentBookingData.pagination;
  }

  //Loading
  get isLoading() {
    return ShipmentBookingController.isLoading || BalanceController.isLoading;
  }

  get isLoadingSTTSummary() {
    return STIController.isLoadingSTTSummary;
  }

  //Error
  get isError() {
    return ShipmentBookingController.isError;
  }

  //Error Cause
  get errorCause() {
    return ShipmentBookingController.isErrorCause;
  }

  //misc
  modalInputShipmentId = false;
  refs: any = {};
  togglePanel(event: any) {
    this.onSetRefs();
    const refs: any = this.refs;
    refs["op"]?.toggle(event);
  }
  get panelVisible() {
    return this.refs.op?.visible;
  }

  fetchNeedStiSummary() {
    if (this.isAccountPOS) {
      const payload = new STISTTDueSummaryListRequest({
        bookedId: AccountController.accountData.account_type_detail.id,
        bookedType: "pos"
      });

      STIController.getSTISTTDueSummary(payload);
    }
  }

  onRefreshSTISummary = debounce(() => {
    this.fetchNeedStiSummary();
  }, 250);

  get STISTTDueSummaryData() {
    return STIController.STISTTDueSummaryData;
  }

  gotoNeedToStiPage(isNow = false, isOverdue = false) {
    router.push({
      name: "need-to-sti-booking",
      query: { isNow: String(isNow), isOverdue: String(isOverdue) }
    });
  }

  get isAccountPOS() {
    return AccountController.accountData.isPosAccount;
  }

  get columnsOne() {
    return [
      {
        name: "No. STT",
        styleHead: "w-50 text-left whitespace-no-wrap",
        styleBody: "text-red-lp-200 text-center",
        render: (item: ShipmentBooking) => {
          return `
            <div class="flex flex-row items-center">
              <div class='text-left mr-1'>${item.sttNo}</div>
              ${
                item.sttIsCod
                  ? item.sttIsDfod
                    ? `<img src="${require("@/app/ui/assets/svg/dfod-badge.svg")}" />`
                    : `<img src="${require("@/app/ui/assets/svg/cod-badge.svg")}" />`
                  : ""
              }
            </div>
          `;
        }
      },
      {
        name: "No. shipment",
        styleHead: "w-40 text-left whitespace-no-wrap",
        styleBody: "",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300">${item.sttShipmentId}</div>`;
        }
      },
      {
        name: "Alamat tujuan",
        styleHead: "w-64 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="break-all text-black-lp-300 line-clamp-2" title="${item.sttRecipientAddress}">${item.sttRecipientAddress}</div>`;
        }
      }
    ];
  }

  get columnsTwo() {
    return [
      {
        name: "Status terakhir",
        styleHead: "w-40 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded capitalize px-2 py-0 ${this.mapLastStatusColor(
                      item.sttLastStatusId
                    )}">
                        ${item.sttLastStatusId}
                    </div>
                  </div>`;
        },
        toolTip: (item: ShipmentBooking) => {
          if (item.sttLastStatusId === "CODREJ") {
            return `<div class='flex text-black-lp-300 text-12px max-w-xs'>${item.dexCodrejReason}</div>`;
          }
          if (item.sttLastStatusId === "DEX") {
            return `
              <div class="text-xs">
                <h5 class="text-gray-lp-300 font-semibold">Percobaan DEX ke ${item.totalSttDex}</h5>
                <p class="text-gray-lp-600">${item.dexCodrejReason}</p>
              </div>
            `;
          }
        }
      },
      {
        name: "Status adjustment",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: ShipmentBooking) => {
          return `<div class="${this.mapAdjustmentStatusColor(
                      item.sttAssessmentStatus || ""
                    )}">${this.mapAdjustmentStatusText(item.sttAssessmentStatus || "")}</div>`;
        }
      },
      {
        name: "Jenis pengiriman",
        styleHead: "w-40 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded capitalize px-2 py-0 bg-gray-lp-1400 text-gray-lp-300">
                        ${item.sttProductType}
                    </div>
                  </div>`;
        }
      },
      {
        name: "Tanggal booking",
        styleHead: "w-64 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300">${formatDate(
            item.sttCreatedAt
          )}</div>
                  <div class="capitalize text-gray-lp-500">${ellipsisString(
                    item.sttCreatedName,
                    30
                  )}</div>`;
        }
      }
    ];
  }

  get columnsThree() {
    return [
      {
        name: this.$t("bookingShipment.list.table.totalWeight"),
        styleHead: "w-40 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class='flex text-black-lp-300'>${convertDecimalWithComma(
            item.sttChargeAbleWeight,
            2
          )} kg</div>`;
        }
      },
      {
        name: this.$t("bookingShipment.list.table.serviceType"),
        styleHead: "w-40 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                        ${item.sttProductType}
                    </div>
                  </div>`;
        }
      },
      {
        name: this.$t("bookingShipment.list.table.commodity"),
        styleHead: "w-52 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${ellipsisString(item.sttCommodityName, 25)}
                    </div>
                  </div>`;
        }
      },
      {
        name: this.$t("bookingShipment.list.table.createdFor"),
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded capitalize px-2 py-0 bg-gray-lp-400">
                        ${ellipsisString(item.sttBilledTo, 90)}
                    </div>
                  </div>`;
        }
      }
    ];
  }

  get columnsFour() {
    const data = [
      {
        name: this.$t("bookingShipment.list.table.insurance"),
        styleHead: "w-32 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded capitalize px-2 py-0 bg-gray-lp-400">
                        ${item.sttInsuranceType}
                    </div>
                  </div>`;
        }
      },
      {
        name: "Tipe COD",
        styleHead: "w-32 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300 flex gap-2">
                    ${this.renderCodType(item)}
                  </div>`;
        }
      },
      {
        name: this.$t("bookingShipment.list.table.troubledStatus"),
        styleHead: "w-40 text-left",
        render: (item: ShipmentBooking) => {
          if (item.sttWarningStatus) {
            return `<div class="text-black-lp-300 flex">
                    <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                        ${item.sttWarningStatus}
                    </div>
                  </div>`;
          } else {
            return "-";
          }
        },
        toolTip: (item: ShipmentBooking) => {
          if (item.sttWarningStatus) {
            return `<div class='flex text-black-lp-300'>${item.sttWarningStatusDescription}</div>`;
          } else {
            return "";
          }
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-40 text-left",
        showButton: () => {
          return true;
        }
      }
    ];

    if (!this.accountIsNonForeign) {
      data.splice(1, 1);
    }
    return data;
  }

  get columnAction() {
    return [
      {
        name: "Tanggal diupdate",
        styleHead: "w-64 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300">${formatDate(
            item.sttUpdatedAt
          )}</div>
                  <div class="capitalize text-gray-lp-500">${ellipsisString(
                    item.sttUpdateBy || "-",
                    30
                  )}</div>`;
        }
      },
      {
        name: "Dibooking untuk",
        styleHead: "w-64 text-left",
        render: (item: ShipmentBooking) => {
          return `<div class="text-black-lp-300">${item.sttBilledTo ||
            "-"}</div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-40 text-left",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  // Table
  get columns() {
    return [
      ...this.columnsOne,
      ...this.columnsTwo,
      ...this.columnAction
      // ...this.columnsThree
      // ...this.columnsFour
    ];
  }

  get isEditable(): boolean {
    return this.isMigratingFromElexys || !this.isEditAble;
  }

  renderDfod(cod: boolean, dfod: boolean) {
    const result = {
      is: false,
      label: "-"
    };

    if (cod && dfod) {
      (result.is = true), (result.label = "DFOD");
    } else if (cod && !dfod) {
      (result.is = true), (result.label = "COD");
    }

    return result;
  }

  renderCodType(data: ShipmentBooking) {
    const newCod = this.renderDfod(data.sttIsCod, data.sttIsDfod);

    const isCod = { is: newCod.is, label: newCod.label };
    const isPad = { is: data.sttIsPad, label: "PAD" };
    const sttCodType = [] as string[];
    [isCod, isPad].forEach((e: any) => {
      if (e.is) {
        sttCodType.push(e.label);
      }
    });
    return sttCodType.length
      ? sttCodType
          .map(stt => ChipsStyles({ status: "gray", title: stt, square: true }))
          .join("")
      : "-";
  }

  get isEditAble() {
    return FlagsPermissionBooking.permission_booking_edit.isEnabled();
  }
  isEditAbleBasedOnAccountType(lastStatus: string) {
    if (this.dataProfile.account_type === "internal") {
      return (
        lastStatus !== "CNX" &&
        lastStatus !== "SCRAP" &&
        lastStatus !== "POD" &&
        lastStatus !== "CODREJ"
      );
    }
    return false;
  }
  listActionForPos(item: any, language: string) {
    const listForPos = [
      {
        icon: "pencil-alt-disable",
        label: this.$t("bookingShipment.list.action.edit"),
        clickFunction: () => this.goToEdit(item.sttId)
      },
      {
        icon: "printer",
        label: this.$t("bookingShipment.list.action.thermalReceipt"),
        clickFunction: () => this.print(item.sttId, "thermal", language)
      },
      {
        icon: "document-download-outline-grey",
        label: this.$t("bookingShipment.list.action.thermalPdf"),
        clickFunction: () => this.print(item.sttId, "thermal-pdf", language)
      },
      {
        icon: "document-download-outline-grey",
        label: this.$t("bookingShipment.list.action.basicPdf"),
        clickFunction: () => this.print(item.sttId, "basic", language)
      },
      {
        icon: "help-desk",
        label: "Laporkan Kendala",
        clickFunction: () => this.goToCreatePusatResolusi(item.sttNo)
      }
    ];

    if (!this.accountIsNonForeign) {
      listForPos.splice(4, 1);
    }

    return listForPos;
  }

  actionGroupOptions(item: any) {
    const language = item.sttProductType === "INTERPACK" ? "en" : "id";

    const groupOptions =
      this.dataProfile.account_type_detail.type === "pos"
        ? this.listActionForPos(item, language)
        : [
            {
              icon: "pencil-alt-disable",
              label: this.$t("bookingShipment.list.action.edit"),
              clickFunction: () => this.goToEdit(item.sttId)
            },
            {
              icon: "printer",
              label: this.$t("bookingShipment.list.action.thermalReceipt"),
              clickFunction: () => this.print(item.sttId, "thermal", language)
            },
            {
              icon: "document-download-outline-grey",
              label: this.$t("bookingShipment.list.action.thermalPdf"),
              clickFunction: () =>
                this.print(item.sttId, "thermal-pdf", language)
            },
            {
              icon: "document-download-outline-grey",
              label: this.$t("bookingShipment.list.action.basicPdf"),
              clickFunction: () => this.print(item.sttId, "basic", language)
            }
          ];
    const invoice = {
      icon: "document-download-outline-grey",
      label: "Print Commercial Invoice",
      clickFunction: () =>
        this.print(item.sttId, "commercial-invoice", language)
    };
    const newGroupOptions =
      item.sttProductType === "INTERPACK"
        ? [...groupOptions, invoice]
        : groupOptions;

    if (
      this.isEditable ||
      !this.isEditAbleBasedOnAccountType(item.sttLastStatusId)
    ) {
      return newGroupOptions.slice(1, 6);
    }
    return newGroupOptions;
  }

  async print(sttId: number, type = "", language = "id") {
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () => book.default.methods.printThermal(sttId, language),
      "thermal-pdf": () =>
        book.default.methods.printBasic(sttId, 0, language, false, true),
      basic: () => book.default.methods.printBasic(sttId, 1, language),
      "commercial-invoice": () =>
        book.default.methods.directPrintInterpack(sttId)
    };

    printType[type]();
  }

  goToDetail(item: any) {
    this.$router.push(`/shipment/booking/${item.sttId}`);
  }
  goToEdit(id: number) {
    router.push(`/shipment/booking/${id}/edit`);
  }

  goToCreatePusatResolusi(id: string) {
    dataLayer("laporkan_kendala_booking_list_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
    router.push(`/pusat-resolusi/create?sttNo=${id}`);
  }

  async goToCreate() {
    if (this.isBalanceUnderLimitForPos) {
      this.balanceUnderLimit = true;
      return;
    }
    await this.$router.push("/shipment/booking/create");
    dataLayer("create_booking_manual_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToCreateClientBook() {
    this.$router.push("/shipment/booking/create-client");
    dataLayer("create_booking_client_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToCreateShipmentBook() {
    this.$router.push(`/shipment/booking/create/by-shipment-id`);
    dataLayer("create_booking_shipment_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  goToInstantBooking() {
    this.tncModal = false;
    InstantBookingController.setIsInstantBookingTncConfirmed(true);
    this.$router.push("/shipment/booking/create/instant-booking-shipment");
    dataLayer("create_booking_instant_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isPosChild() {
    return AccountController.accountData.isPosChildAccount;
  }

  get isInternal() {
    return AccountController.accountData.isInternalAccount;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
        this.dataProfile.limit_rule_min_balance &&
      this.dataProfile.account_type === "partner"
    );
  }

  get permissionList() {
    const permissions = [
      {
        status: FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled(),
        title: "Booking Shipment",
        action: this.goToCreateShipmentBook
      },
      {
        status: FlagsPermissionBooking.permission_booking_create_manual.isEnabled(),
        title:
          this.dataProfile.account_type === "client"
            ? "Booking Klien"
            : this.$t("bookingShipment.list.action.createBooking"),
        action: this.goToCreate
      },
      {
        status: FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled(),
        title: "Booking Klien",
        action: this.goToCreateClientBook
      },
      {
        status: FlagsPermissionBooking.permission_instant_booking_create.isEnabled(),
        title: "Booking Instan",
        action: this.goToInstantBooking
      }
    ];

    const result = permissions.filter((item: any) => item.status);
    return {
      data: result,
      title: result[0]?.title,
      action: result[0]?.action,
      total: result.length
    };
  }

  // cancel booking
  isRejectPopup = false;
  sttNumberSelected = "";
  onOpenPopupCancelBooking(item: any) {
    this.sttNumberSelected = item?.sttNo;
    this.isRejectPopup = true;
  }
  hitGtmBookingClicked() {
    dataLayer("create_booking_button_clicked", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  onCloseTncModal() {
    this.tncModal = false;
  }

  // check last status red color
  checkLastStatusRedColor(status: string): boolean {
    return [
      "DEX",
      "SCRAP",
      "SHORTLAND",
      "MIS-ROUTE",
      "CODREJ",
      "MISSING",
      "DAMAGE",
      "NOT RECEIVE",
      "NOT RECEIVED",
      "MISBOOKING",
      "REJECTED"
    ].includes(status);
  }

  // check last status blue color
  checkLastStatusBlueColor(status: string): boolean {
    return [
      "CNX",
      "POD",
      "RTS",
      "CLAIM",
      "CI",
      "RTSHQ",
      "STT ADJUSTED POD",
      "REROUTE"
    ].includes(status);
  }

  // mapping last status color
  mapLastStatusColor(status: string): string {
    const uppercaseStatus = status.toUpperCase();
    if (this.checkLastStatusBlueColor(uppercaseStatus))
      return "bg-blue-lp-600 text-blue-lp-700";
    if (this.checkLastStatusRedColor(uppercaseStatus))
      return "bg-red-lp-1300 text-red-lp text-red-lp-510";
    return "bg-gray-lp-1400 text-gray-lp-300";
  }

  // mapping adjustment status color
  mapAdjustmentStatusColor(status: string): string {
    if (status === "approved")
      return "text-green-lp-400";
    if (status === "rejected")
      return "text-red-lp-500";
    if (status === "waiting")
      return "text-yellow-lp-500";
    return "";
  }

  // mapping adjustment status text
  mapAdjustmentStatusText(status: string): string {
    if (status === "approved")
      return "Diterima";
    if (status === "rejected")
      return "Ditolak";
    if (status === "waiting")
      return "Menunggu";
    return "";
  }
}
